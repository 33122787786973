/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  /* Don't Do this as it cause toomany design overwrite problems, put in the body instead */
}

:root {
  --primary: #000;
  --secondary: #0dad09;
  --tertiary: #1e2936;
}
button {
  cursor: pointer !important;
  transition: 0.2s;
}
button:active {
  opacity: 0.5;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  background: #2b3b4b;
  min-height: 100vh;
  color: #fff;
}
.myModal .ant-modal-content {
  border-radius: 15px;
}

svg {
  vertical-align: unset !important;
}
